<template>
  <div class="loading">Retrieving planning, please wait...</div>
</template>

<script>
  export default {
    name: 'Loading'
  }
</script>

<style scoped lang="scss">
  .loading {
    width: 900px;
    padding: 50px 10px;
    text-align: center;
  }
</style>
